// This file is generated by compile-translations.js script
// prettier-ignore
module.exports = [
  {
    "languageCode": "af_ZA",
    "languageName": "Afrikaans",
    "languageNativeName": "Afrikaans",
    "translationRatio": 0.049313217743751414
  },
  {
    "languageCode": "ar_SA",
    "languageName": "Arabic",
    "languageNativeName": "اَلْعَرَبِيَّةُ",
    "translationRatio": 0.946070704796217
  },
  {
    "languageCode": "az_AZ",
    "languageName": "Azerbaijani",
    "languageNativeName": "azərbaycan dili",
    "translationRatio": 0.040081062823688396
  },
  {
    "languageCode": "bg_BG",
    "languageName": "Bulgarian",
    "languageNativeName": "български език",
    "translationRatio": 0.08365233055618104
  },
  {
    "languageCode": "bn_BD",
    "languageName": "Bengali",
    "languageNativeName": "বাংলা",
    "translationRatio": 0.05471740598964192
  },
  {
    "languageCode": "br_FR",
    "languageName": "Breton",
    "languageNativeName": "brezhoneg",
    "translationRatio": 0.027696464760189166
  },
  {
    "languageCode": "ca_ES",
    "languageName": "Catalan",
    "languageNativeName": "Català",
    "translationRatio": 0.2374465210538167
  },
  {
    "languageCode": "cs_CZ",
    "languageName": "Czech",
    "languageNativeName": "čeština",
    "translationRatio": 0.26581850934474216
  },
  {
    "languageCode": "da_DK",
    "languageName": "Danish",
    "languageNativeName": "Dansk",
    "translationRatio": 0.12778653456428735
  },
  {
    "languageCode": "de_DE",
    "languageName": "German",
    "languageNativeName": "Deutsch",
    "translationRatio": 0.742512947534339
  },
  {
    "languageCode": "el_GR",
    "languageName": "Greek",
    "languageNativeName": "Ελληνικά",
    "translationRatio": 0.23485701418599414
  },
  {
    "languageCode": "en",
    "languageName": "English",
    "languageNativeName": "English",
    "translationRatio": 0.0719107719401233
  },
  {
    "languageCode": "eo_UY",
    "languageName": "Esperanto",
    "languageNativeName": "Esperanto",
    "translationRatio": 0.20625985138482328
  },
  {
    "languageCode": "es_ES",
    "languageName": "Spanish",
    "languageNativeName": "Español",
    "translationRatio": 0.94246791263229
  },
  {
    "languageCode": "fa_IR",
    "languageName": "Persian",
    "languageNativeName": "فارسی",
    "translationRatio": 0.22235982886737227
  },
  {
    "languageCode": "fi_FI",
    "languageName": "Finnish",
    "languageNativeName": "suomi",
    "translationRatio": 0.21515424453951815
  },
  {
    "languageCode": "fil_PH",
    "languageName": "Filipino",
    "languageNativeName": "Mga Filipino",
    "translationRatio": 0.2681828417023193
  },
  {
    "languageCode": "fr_FR",
    "languageName": "French",
    "languageNativeName": "Français",
    "translationRatio": 0.9029497860842153
  },
  {
    "languageCode": "ha_HG",
    "languageName": "Hausa",
    "languageNativeName": "هَوُسَ",
    "translationRatio": 0.03276289124071152
  },
  {
    "languageCode": "he_IL",
    "languageName": "Hebrew",
    "languageNativeName": "עברית",
    "translationRatio": 0.1747354199504616
  },
  {
    "languageCode": "hi_IN",
    "languageName": "Hindi",
    "languageNativeName": "हिन्दी",
    "translationRatio": 0.14613825714929074
  },
  {
    "languageCode": "hu_HU",
    "languageName": "Hungarian",
    "languageNativeName": "magyar",
    "translationRatio": 0.37086241837424005
  },
  {
    "languageCode": "id_ID",
    "languageName": "Indonesian",
    "languageNativeName": "Bahasa Indonesia",
    "translationRatio": 0.47725737446521055
  },
  {
    "languageCode": "ig_NG",
    "languageName": "Igbo",
    "languageNativeName": "Asụsụ Igbo",
    "translationRatio": 0.031411844179238924
  },
  {
    "languageCode": "it_IT",
    "languageName": "Italian",
    "languageNativeName": "Italiano",
    "translationRatio": 0.9788335960369287
  },
  {
    "languageCode": "ja_JP",
    "languageName": "Japanese",
    "languageNativeName": "日本語",
    "translationRatio": 0.9854762440891691
  },
  {
    "languageCode": "ka_GE",
    "languageName": "Georgian",
    "languageNativeName": "ქართული",
    "translationRatio": 0.05809502364332353
  },
  {
    "languageCode": "km_KH",
    "languageName": "Khmer",
    "languageNativeName": "ខេមរភាសា",
    "translationRatio": 0.03197478045485247
  },
  {
    "languageCode": "ko_KR",
    "languageName": "Korean",
    "languageNativeName": "한국어",
    "translationRatio": 0.8477820310740825
  },
  {
    "languageCode": "lt_LT",
    "languageName": "Lithuanian",
    "languageNativeName": "lietuvių kalba",
    "translationRatio": 0.46408466561585227
  },
  {
    "languageCode": "lv_LV",
    "languageName": "Latvian",
    "languageNativeName": "latviešu valoda",
    "translationRatio": 0.027696464760189166
  },
  {
    "languageCode": "mr_IN",
    "languageName": "Marathi",
    "languageNativeName": "मराठी",
    "translationRatio": 0.039405539292951985
  },
  {
    "languageCode": "ms_MY",
    "languageName": "Malay",
    "languageNativeName": "Bahasa Melayu",
    "translationRatio": 0.05629362756136003
  },
  {
    "languageCode": "my_MM",
    "languageName": "Burmese",
    "languageNativeName": "ဗမာစာ",
    "translationRatio": 0.02994820986264357
  },
  {
    "languageCode": "nl_NL",
    "languageName": "Dutch",
    "languageNativeName": "Nederlands",
    "translationRatio": 0.45980634992118896
  },
  {
    "languageCode": "no_NO",
    "languageName": "Norwegian",
    "languageNativeName": "Norsk",
    "translationRatio": 0.3630938977707724
  },
  {
    "languageCode": "pl_PL",
    "languageName": "Polish",
    "languageNativeName": "Polski",
    "translationRatio": 0.9766944381895969
  },
  {
    "languageCode": "pseudo_LOCALE",
    "languageName": "for development only",
    "languageNativeName": "Pseudolocalization",
    "translationRatio": 0.995890813031993
  },
  {
    "languageCode": "pt_BR",
    "languageName": "Brazilian Portuguese",
    "languageNativeName": "Português brasileiro",
    "translationRatio": 0.8656834046385949
  },
  {
    "languageCode": "pt_PT",
    "languageName": "Portuguese",
    "languageNativeName": "Português",
    "translationRatio": 0.325039405539293
  },
  {
    "languageCode": "ro_RO",
    "languageName": "Romanian",
    "languageNativeName": "Română",
    "translationRatio": 0.09727538842603012
  },
  {
    "languageCode": "ru_RU",
    "languageName": "Russian",
    "languageNativeName": "Русский",
    "translationRatio": 0.9799594685881559
  },
  {
    "languageCode": "si_LK",
    "languageName": "Sinhala",
    "languageNativeName": "සිංහල",
    "translationRatio": 0.5951362305786985
  },
  {
    "languageCode": "sk_SK",
    "languageName": "Slovak",
    "languageNativeName": "slovenčina",
    "translationRatio": 0.1697815807250619
  },
  {
    "languageCode": "sl_SI",
    "languageName": "Slovenian",
    "languageNativeName": "slovenščina",
    "translationRatio": 0.6494032875478496
  },
  {
    "languageCode": "sq_AL",
    "languageName": "Albanian",
    "languageNativeName": "Shqip",
    "translationRatio": 0.15469488853861746
  },
  {
    "languageCode": "sr_CS",
    "languageName": "Serbian (Latin)",
    "languageNativeName": "srpski",
    "translationRatio": 0.1357802296780004
  },
  {
    "languageCode": "sr_SP",
    "languageName": "Serbian",
    "languageNativeName": "српски језик",
    "translationRatio": 0.2811303760414321
  },
  {
    "languageCode": "sv_SE",
    "languageName": "Swedish",
    "languageNativeName": "Svenska",
    "translationRatio": 0.18025219545147486
  },
  {
    "languageCode": "sw_KE",
    "languageName": "Swahili",
    "languageNativeName": "Kiswahili",
    "translationRatio": 0.02780905201531192
  },
  {
    "languageCode": "th_TH",
    "languageName": "Thai",
    "languageNativeName": "ไทย",
    "translationRatio": 0.2697590632740374
  },
  {
    "languageCode": "tr_TR",
    "languageName": "Turkish",
    "languageNativeName": "Türkçe",
    "translationRatio": 0.9471965773474442
  },
  {
    "languageCode": "uk_UA",
    "languageName": "Ukrainian",
    "languageNativeName": "Українська",
    "translationRatio": 0.9903174960594461
  },
  {
    "languageCode": "ur_PK",
    "languageName": "Urdu",
    "languageNativeName": "اردو",
    "translationRatio": 0.03704120693537494
  },
  {
    "languageCode": "uz_UZ",
    "languageName": "Uzbek",
    "languageNativeName": "Ўзбек",
    "translationRatio": 0.03152443143436168
  },
  {
    "languageCode": "vi_VN",
    "languageName": "Vietnamese",
    "languageNativeName": "Tiếng Việt",
    "translationRatio": 0.297680702544472
  },
  {
    "languageCode": "yo_NG",
    "languageName": "Yoruba",
    "languageNativeName": "Yorùbá",
    "translationRatio": 0.26289124071155145
  },
  {
    "languageCode": "zh_CN",
    "languageName": "Chinese Simplified",
    "languageNativeName": "简化字",
    "translationRatio": 0.9922314793965323
  },
  {
    "languageCode": "zh_TW",
    "languageName": "Chinese Traditional",
    "languageNativeName": "正體字",
    "translationRatio": 0.8863994595811754
  }
];
